import { FormControl, FormLabel, RadioGroup, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { isItem86edToday } from '../../../utils/menu';
import { SingularModChoice } from '../components/ModChoice';
import { ModGroupLabel } from '../components/ModGroupLabel';
import { RecursiveModGroup } from '../components/RecursiveModGroup';
import { sortAndFilterMenuItems } from '../util/helper';
import { IModGroup } from './modGroup.props';
import { hasModGroupChildren } from './modGroup.utils';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  modGroup: {
    margin: `0 !important`,
    '& legend': {
      width: '100%',
    },
    width: '100%',
  },
  singularSelectionModGroupClass: {
    marginBottom: (hasChildren) => (hasChildren ? spacing(1.25) : 0),
  },
}));

export default function SingularSelectionModGroup(props: IModGroup) {
  const { modGroup, onChange, cartModGroup, cartItem } = props;
  let currentValue: string | null = null;
  let selectedModNumber = 0;
  if (cartModGroup) {
    const selectedMod = cartModGroup.selectedItems;
    currentValue = Object.values(selectedMod)[0]?.itemId || null;
    selectedModNumber = Object.values(selectedMod).length;
  } else if (cartItem.childModifierGroups[modGroup.id]) {
    const childModGroup = cartItem.childModifierGroups[modGroup.id];
    const selectedMod = childModGroup.selectedItems;
    currentValue = Object.values(selectedMod)[0]?.itemId || null;
    selectedModNumber = Object.values(selectedMod).length;
  }

  // sort and filter the modifiers before rendering
  const menuItems = sortAndFilterMenuItems(modGroup.menuItems);
  const key = [cartItem.id, modGroup.id].join('_');
  const classes = useStyles(hasModGroupChildren(props));

  return (
    <div key={key} className={classes.singularSelectionModGroupClass}>
      <FormControl component="fieldset" className={classes.modGroup}>
        <FormLabel component="legend">
          <ModGroupLabel modGroup={modGroup} selectedModNumber={selectedModNumber} />
        </FormLabel>
        <RadioGroup row aria-label={modGroup.name} name={modGroup.name} value={currentValue} onChange={onChange(modGroup)}>
          {menuItems.map((child) => {
            if (child.available || isItem86edToday(child)) {
              return <SingularModChoice key={key + child.id} item={child} currentValue={currentValue} />;
            }
            return null;
          })}
        </RadioGroup>
      </FormControl>
      <RecursiveModGroup {...props} />
    </div>
  );
}

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Menu, MenuItem, TextField, Theme, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMenuVersion } from '../../reducers/menuSlice';
import { menuVersionsSelector, selectedMenuVersionSelector } from '../../selectors/menu';
import { generateMenuTitle, IMenuVersion } from '../../utils/menu';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1, 2),
  },
  menu: {
    '& .MuiMenu-paper': {
      width: '255px',
      maxHeight: '230px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  input: {
    color: '#616161',
  },
}));

const MenuVersionPicker: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuVersions = useSelector(menuVersionsSelector);
  const { commitId } = useSelector(selectedMenuVersionSelector);
  const [search, setSearch] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const filterRef = React.useRef<HTMLInputElement>();
  const isMenuOpen = Boolean(anchorEl);
  const title = commitId === 'latest' ? 'latest' : `Version ${commitId}`;
  const showSearchInput = menuVersions.length > 5;
  const filteredMenuVersions =
    showSearchInput && search
      ? menuVersions.filter(({ commitId, comment }) => {
          return generateMenuTitle(commitId, comment || '')
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase());
        })
      : menuVersions;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const stopPropagation = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break;
      default:
        e.stopPropagation();
    }
  };

  const moveFocusToInput = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Tab' || e.key === 'ArrowRight') {
      e.stopPropagation();
      e.preventDefault();
      if (filterRef?.current) {
        filterRef.current.focus();
      }
    }
  };

  const handleMenuVersionChange = (currentMenuVersion: IMenuVersion) => {
    setSearch('');
    handleClose();
    dispatch(selectMenuVersion(currentMenuVersion));
  };

  return (
    <>
      <Button
        disableElevation
        aria-controls="menu-version-picker"
        aria-haspopup="true"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        className={classes.button}
        color="secondary"
        variant="contained"
      >
        {title}
        <ArrowDropDownIcon />
      </Button>
      <Menu id="menu-version-picker" className={classes.menu} keepMounted anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose} variant="menu">
        {showSearchInput && (
          <MenuItem onKeyDown={moveFocusToInput}>
            <TextField
              data-testid="menu-version-search"
              className={classes.input}
              inputRef={filterRef}
              value={search}
              onKeyDown={stopPropagation}
              onChange={(e) => {
                setSearch(e.currentTarget.value);
              }}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
          </MenuItem>
        )}
        {filteredMenuVersions.map((menuVersion) => {
          const { commitId, comment } = menuVersion;
          const title = generateMenuTitle(commitId, comment);
          return (
            <MenuItem
              key={commitId}
              data-testid="menu-version"
              onClick={() => {
                if (commitId) {
                  handleMenuVersionChange(menuVersion);
                }
              }}
            >
              <Tooltip title={title} arrow>
                <Typography>{title}</Typography>
              </Tooltip>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default memo(MenuVersionPicker);

import { FormControl, FormGroup, FormLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { isItem86edToday } from '../../../utils/menu';
import { MultipleModChoice } from '../components/ModChoice';
import { ModGroupLabel } from '../components/ModGroupLabel';
import { RecursiveModGroup } from '../components/RecursiveModGroup';
import { sortAndFilterMenuItems } from '../util/helper';
import { IModGroup } from './modGroup.props';
import { hasModGroupChildren } from './modGroup.utils';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  modGroup: {
    margin: `0 !important`,
    '& legend': {
      width: '100%',
    },
    width: '100%',
  },
  multipleSelectionModGroupClass: {
    marginBottom: (hasChildren) => (hasChildren ? spacing(1.25) : 0),
  },
}));

export default function MultipleSelectionModGroup(props: IModGroup) {
  const { modGroup, onChange, cartModGroup, cartItem } = props;

  //sort and filter the modifiers before rendering
  const menuItems = sortAndFilterMenuItems(modGroup.menuItems);

  let selectedModNumber = 0;
  if (cartModGroup) {
    const selectedMod = cartModGroup.selectedItems;
    selectedModNumber = Object.values(selectedMod).length;
  }
  const key = [cartItem.id, modGroup.id].join('_');
  const classes = useStyles(hasModGroupChildren(props));

  return (
    <div key={key} className={classes.multipleSelectionModGroupClass}>
      <FormControl component="fieldset" className={classes.modGroup}>
        <FormLabel component="legend">
          <ModGroupLabel modGroup={modGroup} selectedModNumber={selectedModNumber} />
        </FormLabel>
        <FormGroup row>
          {menuItems.map((child) => {
            if (child.available || isItem86edToday(child)) {
              return (
                <MultipleModChoice
                  key={key + child.id}
                  childModifierGroup={cartItem.childModifierGroups[modGroup.id]}
                  menuItem={child}
                  cartModGroup={cartModGroup}
                  onChange={onChange(modGroup)}
                />
              );
            }
            return null;
          })}
        </FormGroup>
      </FormControl>
      <RecursiveModGroup {...props} />
    </div>
  );
}

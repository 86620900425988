import { Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectIsAgentAvailable, selectIsTaskOffered } from '../../redux/features/taskRouter/taskRouter.selector';
import { toggleAgentAvailability } from '../../redux/features/taskRouter/taskRouter.slice';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  taskRouterBodyClass: {
    height: `calc(100vh - ${spacing(8)})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButtonClass: {
    marginTop: `${spacing(2)} !important`,
    textTransform: 'uppercase',
  },
}));

const TaskRouterBody = () => {
  const { taskRouterBodyClass, actionButtonClass } = useStyles();
  const dispatch = useAppDispatch();
  const isAgentAvailable = useAppSelector(selectIsAgentAvailable);
  const isTaskOffered = useAppSelector(selectIsTaskOffered);
  const handleAvailability = () => dispatch(toggleAgentAvailability());

  const renderContent = () => {
    switch (true) {
      case !isAgentAvailable: {
        return (
          <>
            <Typography variant="h4" gutterBottom>
              You're Unavailable to take Orders
            </Typography>
            <Typography>You can't take orders when Unavailable.</Typography>
            <Typography>When you're ready, switch your status to Available to start taking orders.</Typography>
            <Button variant="contained" color="secondary" onClick={handleAvailability} className={actionButtonClass}>
              change status to available
            </Button>
          </>
        );
      }
      case isAgentAvailable && isTaskOffered: {
        return (
          <>
            <Typography variant="h4" gutterBottom>
              New task available!
            </Typography>
            <Typography>Press Spacebar to accept</Typography>
            <Typography>or click the button below</Typography>
            <Button variant="contained" color="secondary" onClick={handleAvailability} className={actionButtonClass}>
              accept task
            </Button>
          </>
        );
      }
      case isAgentAvailable: {
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Get ready
            </Typography>
            <Typography>New tasks will be offered to you shortly</Typography>
          </>
        );
      }
      default:
        break;
    }
  };

  return (
    <div id="task-router-body" className={taskRouterBodyClass}>
      {renderContent()}
    </div>
  );
};

export default TaskRouterBody;

import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { AgentTypes, EventTypes } from '../constants/event';
import { cyborgAlert } from '../utils/alert';
import { PcmAudioPlayer } from '../utils/audio';
import { selectMenuVersion } from './menuSlice';
import { OrderTransmissionMessage } from './orderSlice';
import { selectRestaurant, selectStage } from './restaurantSlice';

export enum SessionSource {
  hitl = 'HITL',
  communicator = 'COMMUNICATOR',
}

export enum RestaurantStaffInterventionStatus {
  initial = 'INITIAL',
  open = 'OPEN',
  close = 'CLOSE',
}

export interface TransmissionMessage {
  seq: number;
  id: string;
  event: EventTypes;
  timestamp: string;
  agent_id: string;
  agent_type: AgentTypes;
  session_id: string;
  metadata?: any;
}

export interface NewTransmissionMessage extends TransmissionMessage {
  data: {
    terminal_id: string;
    restaurant_code: string;
    session_id: string;
    media_format: string;
  };
}

export interface EndSessionTransmissionMessage extends TransmissionMessage {
  data: {
    restaurant_code: string;
    session_id: string;
  };
}

export interface AudioFrameTransmissionMessage extends TransmissionMessage {
  data: {
    payload: string;
    source_name: string;
  };
}

export interface TextFrameTransmissionMessage extends TransmissionMessage {
  data: {
    status: 'hypothesis' | 'final' | 'TTS';
    payload: string;
    metadata?: any;
  };
}

export interface EntityMenuItem {
  id: string; // the id of the menu item or modifier and HITL will handle the time based menu and availabilty
  name: string;
  is_custom_text: boolean;
  price: number;
  tax: number;
  available: boolean;
  quantity: number;
  pos_specific_properties: {
    pos_id: number;
  };
  children: EntityMenuItem[] | [];
}

export interface Hypothesis {
  dialog: string[];
  responses: string[];
  customer_intent: 'order' | 'other';
  confidence: number;
  order_items: EntityMenuItem[];
}

export interface HypothesisTransmissionMessage extends TransmissionMessage {
  data: {
    hypotheses: Hypothesis[]; // will only have one hypothesis object in the hypotheses field for each session for now
  };
}

export interface InfoTransmissionMessage extends TransmissionMessage {
  data: {
    type: 'INFO' | 'METRIC' | EventTypes.hypothesis;
    message: string;
    metadata?: any; // This is really a json type per the spec, but... shocked TS doesn't have this.
  };
}

export interface StaffInfoTransmissionMessage extends TransmissionMessage {
  data: {
    payload: string;
    locale: string;
  };
}

export interface CheckTransmissionMessage extends TransmissionMessage {
  data: {
    check?: {
      total: string;
      subtotal: string;
      tax: string;
    };
    error?: string;
    context?: string;
    display?: string;
    notification_enabled: boolean;
    request: string;
    status: 'error' | 'ok';
    transaction_id: string;
  };
}

export interface ErrorTransmissionMessage extends TransmissionMessage {
  data: {
    message: string;
  };
}

export interface MessagingState {
  isEstablishingConnection: boolean;
  isConnected: boolean;
  sentTextFrames: TextFrameTransmissionMessage[];
  inProgressHypothesisTextFrames: TextFrameTransmissionMessage[];
  sentInfo: InfoTransmissionMessage[];
  sentEnd: EndSessionTransmissionMessage[];
  sendOrder: OrderTransmissionMessage[];
  finalTextFrames: TextFrameTransmissionMessage[];
  audioFrames: AudioFrameTransmissionMessage[];
  audioBuffer: BufferedAudioState;
  startFrame: NewTransmissionMessage | null;
  hypothesisFrame: HypothesisTransmissionMessage | null;
  handledHypothesisFrames: Record<string, number[]>;
  currentSessionId: string;
  isRestaurantStaffJoined: Record<string, boolean>;
  isAIActive: boolean;
  isPlaying: boolean;
  player: PcmAudioPlayer;
  isNewSession: boolean;
  isCarPresent: boolean;
  isStaffIntervention: boolean;
  sessionSource: SessionSource;
  isTTSOn: boolean;
  sentTTSRequest: TransmissionMessage[];
  sentError: ErrorTransmissionMessage[];
  restaurantStaffIntervention: RestaurantStaffInterventionStatus;
}

export interface BufferedAudioState {
  frames: AudioFrameTransmissionMessage[];
  indexLocations: { [seq: number]: number };
  bufferedSeqs: number[];
  lastBufferFlush: number;
}

const initialState: MessagingState = {
  isEstablishingConnection: false,
  isConnected: false,
  currentSessionId: `${uuidv4()}`,
  sentTextFrames: [],
  sentInfo: [],
  sentEnd: [],
  sendOrder: [],
  inProgressHypothesisTextFrames: [],
  finalTextFrames: [],
  audioFrames: [],
  audioBuffer: {
    frames: [],
    indexLocations: {},
    bufferedSeqs: [],
    lastBufferFlush: 0,
  },
  startFrame: null,
  hypothesisFrame: null,
  handledHypothesisFrames: {},
  isRestaurantStaffJoined: {},
  isAIActive: true,
  isPlaying: false,
  player: new PcmAudioPlayer(),
  isNewSession: false,
  isCarPresent: false,
  isStaffIntervention: false,
  sessionSource: SessionSource.hitl,
  isTTSOn: false,
  sentTTSRequest: [],
  sentError: [],
  restaurantStaffIntervention: RestaurantStaffInterventionStatus.initial,
};

const clearOutBufferedAudio = (state: MessagingState) => {
  state.audioBuffer.frames = [];
  state.audioBuffer.bufferedSeqs = [];
  state.audioBuffer.indexLocations = [];
  state.audioBuffer.lastBufferFlush = new Date().getTime();
};

export const messagingSlice = createSlice({
  name: 'messaging',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    startConnecting: (state) => {
      state.isConnected = false;
      state.isEstablishingConnection = true;
    },
    connectionEstablished: (state) => {
      state.isConnected = true;
      state.isEstablishingConnection = false;
    },
    connectionLost: (state) => {
      state.isConnected = false;
      state.isEstablishingConnection = true;
    },
    messageReceived: (state, action: PayloadAction<TransmissionMessage[]>) => {
      const messages = action.payload;
      const buffer = state.audioBuffer;
      messages.forEach((message) => {
        if (message.event === 'check') {
          //check event is handled in orderSlice
          return;
        }
        if (state.currentSessionId !== message.session_id) {
          state.currentSessionId = message.session_id;
          state.isAIActive = true;
          state.sessionSource = SessionSource.communicator;
        }
        //console.log('-currentSessionId in message state-', state.currentSessionId)
        switch (message.event) {
          case EventTypes.start:
            // TODO handle new start frame
            state.startFrame = message as NewTransmissionMessage;
            state.audioBuffer.lastBufferFlush = new Date().getTime();
            break;
          case EventTypes.audio:
            if (!state.isPlaying) {
              return;
            }
            const audio = message as AudioFrameTransmissionMessage;
            if (audio.data.source_name === 'RESTAURANTSTAFF' && message.session_id && state.isRestaurantStaffJoined[message.session_id] === undefined) {
              state.isRestaurantStaffJoined[message.session_id] = true;
            }

            if (audio.data.source_name !== 'ORDERBOARD') {
              // TODO Ignore non-guest audio
              // Rendering multiple audio streams is going to be a challenge.
              // But we can defer it.
              break;
            }
            state.audioFrames.push(message as AudioFrameTransmissionMessage);

            buffer.indexLocations[message.seq] = state.audioFrames.length - 1;
            buffer.bufferedSeqs.push(message.seq);

            if (new Date().getTime() - buffer.lastBufferFlush >= 150) {
              buffer.frames = buffer.bufferedSeqs.sort().map((seq) => state.audioFrames[buffer.indexLocations[seq]]);
              buffer.bufferedSeqs = [];
              buffer.indexLocations = {};
              buffer.lastBufferFlush = new Date().getTime();
              state.audioFrames = [];
            }

            break;
          case EventTypes.text:
            // TODO identify why we're getting an echo
            if (state.sentTextFrames.map((f) => f.id).includes(message.id)) {
              break;
            }

            // TODO check seq id is larger than last
            if ((message as TextFrameTransmissionMessage).data.status === 'hypothesis') {
              state.inProgressHypothesisTextFrames.push(message as TextFrameTransmissionMessage);
            } else if ((message as TextFrameTransmissionMessage).data.status === 'TTS') {
              state.finalTextFrames.push(message as TextFrameTransmissionMessage);
              cyborgAlert((message as TextFrameTransmissionMessage).data.payload);
            } else {
              state.finalTextFrames.push(message as TextFrameTransmissionMessage);
              // Clear out all "in progress" text frames
              state.inProgressHypothesisTextFrames.length = 0;
            }
            break;
          case EventTypes.hypothesis:
            console.log('hypothesis event data: ', message);
            const hypothesisFrame = message as HypothesisTransmissionMessage;
            if (state.currentSessionId === hypothesisFrame.session_id) {
              state.hypothesisFrame = message as HypothesisTransmissionMessage;
            }
            break;
          case EventTypes.startSession:
            state.isNewSession = true;
            state.isStaffIntervention = false;
            state.restaurantStaffIntervention = RestaurantStaffInterventionStatus.initial;

            if (new Date().getTime() - buffer.lastBufferFlush >= 150) {
              buffer.frames = buffer.bufferedSeqs.sort().map((seq) => state.audioFrames[buffer.indexLocations[seq]]);
              buffer.bufferedSeqs = [];
              buffer.indexLocations = {};
              buffer.lastBufferFlush = new Date().getTime();
              state.audioFrames = [];
            }

            break;
          case EventTypes.carEnter:
            state.isNewSession = true;
            state.isCarPresent = true;
            state.restaurantStaffIntervention = RestaurantStaffInterventionStatus.initial;
            break;
          case EventTypes.carExit:
            state.isNewSession = false;
            state.isCarPresent = false;
            state.isStaffIntervention = false;
            break;
          case EventTypes.info:
            if ((message as StaffInfoTransmissionMessage).data.payload === 'StaffInterVention') {
              state.isStaffIntervention = true;
              break;
            }
            break;
          case EventTypes.TTSOff:
            state.isTTSOn = false;
            break;
          case EventTypes.TTSOn:
            state.isTTSOn = true;
            break;
          default:
            console.log('Unhanlded TransmissionMessage Event: ' + message.event);
        }
      });
    },
    sendMessage: (state, action: PayloadAction<TextFrameTransmissionMessage>) => {
      state.sentTextFrames.push(action.payload);
    },
    sendInfo: (state, action: PayloadAction<InfoTransmissionMessage>) => {
      state.sentInfo.push(action.payload);
    },
    handleHypothesisFrame: (state, action: PayloadAction<{ sessionId: string; index: number }>) => {
      // the order item will be pushed to hypothesis array in sequence so keep the idex of order item already handled to reduce duplicate rendering
      state.handledHypothesisFrames[action.payload.sessionId].push(action.payload.index);
    },
    sendEndSession: (state, action: PayloadAction<EndSessionTransmissionMessage>) => {
      state.sentEnd.push(action.payload);
      state.isStaffIntervention = false;
      clearOutBufferedAudio(state);
    },
    sendOrder: (state, action: PayloadAction<OrderTransmissionMessage>) => {
      state.sendOrder.push(action.payload);
    },
    closeRestaurantStaffJoined: (state, action: PayloadAction<{ sessionId: string | null }>) => {
      if (state.isRestaurantStaffJoined && action.payload.sessionId) {
        state.isRestaurantStaffJoined[action.payload.sessionId] = false;
      }
    },
    clearMessages: (state) => {
      state.finalTextFrames = [];
      state.inProgressHypothesisTextFrames = [];
      state.sentTextFrames = [];
    },
    setIsPlaying: (state, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload;
      if (state.isPlaying) {
        state.player.resumeAudio();
      } else {
        state.player.pauseAudio();
        clearOutBufferedAudio(state);
      }
    },
    setIsAIActive: (state, action: PayloadAction<boolean>) => {
      state.isAIActive = action.payload;
    },
    setRestaurantStaffIntervention: (state, action: PayloadAction<RestaurantStaffInterventionStatus>) => {
      state.restaurantStaffIntervention = action.payload;
    },
    resetCarState: (state) => {
      state.isNewSession = false;
      state.isCarPresent = false;
      state.restaurantStaffIntervention = RestaurantStaffInterventionStatus.initial;
      state.isStaffIntervention = false;
    },
    sendTTSRequest: (state, action: PayloadAction<TransmissionMessage>) => {
      state.sentTTSRequest.push(action.payload);
    },
    sendError: (state, action: PayloadAction<ErrorTransmissionMessage>) => {
      state.sentError.push(action.payload);
    },
    resetHypothesisFrame: (state) => {
      state.hypothesisFrame = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(selectRestaurant.fulfilled, selectStage.fulfilled, selectMenuVersion.fulfilled), (state, action) => {
      state.hypothesisFrame = null;
    });
  },
});

export const messagingActions = messagingSlice.actions;
export const { setIsAIActive, resetHypothesisFrame } = messagingSlice.actions;

export default messagingSlice.reducer;

import { Checkbox, Chip, FormControlLabel, Radio, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useShallowSelector } from '../../../app/hooks';
import { CartModifierGroup } from '../../../utils/cart';
import Colors from '../../../utils/color';
import { isItem86edToday, ParsedMenuItem } from '../../../utils/menu';
import { IModGroupStyleProps } from '../menu/ModGroup';
import { getModGroupItemLabel } from '../util/helper';

const useStyles = makeStyles<Theme, IModGroupStyleProps>(({ spacing }) => ({
  button: {
    display: 'none !important',
  },
  chipContainer: {
    borderRadius: `${spacing(0.5)} !important`,
    fontWeight: 'bold !important',
    textTransform: 'uppercase',
    height: `${spacing(5)} !important`,
    width: '100%',
    '& .MuiChip-label': {
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      whiteSpace: 'normal',
      textAlign: 'center',
      lineHeight: '1.2',
    },
  },
  controlLabel: {
    width: '18%',
    margin: `0px ${spacing(1.125)} ${spacing(1.25)} 0px !important`,
    height: `${spacing(5)}`,
    display: 'inline-block !important',
    '& .MuiFormControlLabel-label': {
      display: 'inline-block !important',
      width: '100%',
    },
    '& .MuiChip-root.MuiChip-filled': {
      backgroundColor: Colors.mineShaft,
      color: Colors.white,
    },
    '& .MuiChip-root': {
      cursor: 'pointer',
      fontSize: spacing(1.25),
      color: ({ is86edToday }) => is86edToday && Colors.red,
      textDecoration: ({ is86edToday }) => is86edToday && 'line-through',
    },
  },
}));

interface IMultipleModChoice {
  childModifierGroup: CartModifierGroup;
  menuItem: ParsedMenuItem;
  cartModGroup: CartModifierGroup | null;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function MultipleModChoice({ childModifierGroup, menuItem, cartModGroup, onChange }: IMultipleModChoice) {
  const is86edToday = isItem86edToday(menuItem);
  const classes = useStyles({ is86edToday });
  const modality = useShallowSelector((state) => state.cart.modality);
  const id = menuItem.itemId;
  const isChecked = Boolean(cartModGroup?.selectedItems[id]) || Boolean(childModifierGroup?.selectedItems[id]);

  const ctrl = <Checkbox checked={isChecked} color="primary" value={id} onChange={onChange} name={id} className={classes.button} />;
  const label = (
    <Chip size="small" title={menuItem.name} label={getModGroupItemLabel(menuItem, modality)} variant={isChecked ? 'filled' : 'outlined'} className={classes.chipContainer} />
  );

  return <FormControlLabel key={id} control={ctrl} label={label} className={classes.controlLabel} />;
}
interface ISingularModChoice {
  item: ParsedMenuItem;
  currentValue: string | null;
}

export function SingularModChoice({ currentValue, item }: ISingularModChoice) {
  const is86edToday = isItem86edToday(item);
  const classes = useStyles({ is86edToday });
  const modality = useShallowSelector((state) => state.cart.modality);
  const ctrl = <Radio color="primary" className={classes.button} />;

  const isSelected = currentValue ? currentValue === item.itemId : false;

  const label = <Chip label={getModGroupItemLabel(item, modality)} title={item.name} variant={isSelected ? 'filled' : 'outlined'} className={classes.chipContainer} />;
  return <FormControlLabel value={item.itemId} control={ctrl} label={label} className={classes.controlLabel} />;
}

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { TaskStatuses } from './taskRouter.constants';

const selectTaskRouter = (state: RootState) => state.taskRouter;
const selectIsAgentAvailable = createSelector(selectTaskRouter, (taskRouter) => taskRouter.isAgentAvailable);
const selectTaskStatus = createSelector(selectTaskRouter, (taskRouter) => taskRouter.taskStatus);
const selectIsTaskOffered = createSelector(selectTaskStatus, (status) => status === TaskStatuses.offered);

export { selectTaskRouter, selectIsAgentAvailable, selectTaskStatus, selectIsTaskOffered };

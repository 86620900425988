import { createSlice } from '@reduxjs/toolkit';
import { TaskStatuses } from './taskRouter.constants';

const initialState = {
  isAgentAvailable: true,
  taskStatus: TaskStatuses.none,
};

const taskRouterSlice = createSlice({
  name: 'taskRouter',
  initialState,
  reducers: {
    resetTaskRouterData: (state) => {
      state = { ...initialState };
      return state;
    },
    toggleAgentAvailability: (state) => {
      state.isAgentAvailable = !state.isAgentAvailable;
    },
    updateTaskStatus: (state, action) => {
      state.taskStatus = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { toggleAgentAvailability, resetTaskRouterData, updateTaskStatus } = taskRouterSlice.actions;
export default taskRouterSlice.reducer;

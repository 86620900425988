import { AppBar, Theme, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import packageJSON from '../../../package.json';
import { MenuStages } from '../../constants/enums';
import { IRestaurantInfo } from '../../reducers/restaurantSlice';
import { prodLiveVersionSelector } from '../../selectors/menu';
import { selectedStageSelector } from '../../selectors/restaurant';
import { restaurantInfoSelector } from '../../utils/restaurants';
import HeaderIndicators from '../HeaderIndicators';
import { Logout } from '../Login/Logout';
import MenuVersionPicker from '../MenuVersionPicker';
import MenuVersionStage from '../MenuVersionStage';
import RestaurantsContainer from '../RestaurantsContainer';
import TaskRouterHeaderInfo from '../TaskRouterHeaderInfo';

type HeaderProps = {
  isTaskRouterPage?: boolean;
  targetRestaurant?: IRestaurantInfo;
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingLeft: theme.spacing(2),
    flexGrow: 1,
  },
  restaurantPicker: {
    marginLeft: theme.spacing(2),
  },
  menuVersionPicker: {
    marginLeft: theme.spacing(2),
  },
  logout: {
    marginLeft: theme.spacing(2),
  },
}));

const Header: FC<HeaderProps> = ({ isTaskRouterPage, targetRestaurant }) => {
  const classes = useStyles();
  const title = `Voice Terminal v${packageJSON.version}`;
  const selectedRestaurant = useSelector(restaurantInfoSelector);
  const selectedStage = useSelector(selectedStageSelector);
  const { commitId, comment } = useSelector(prodLiveVersionSelector) || {};

  return (
    <AppBar position="static">
      <Toolbar>
        <img src="/presto_logo.svg" alt="Presto Logo" />
        <Typography className={classes.title}>{title}</Typography>
        {!isTaskRouterPage && (
          <>
            <HeaderIndicators />
            {selectedRestaurant?.restaurantCode && (
              <div className={classes.restaurantPicker}>
                <MenuVersionStage />
              </div>
            )}
            <div className={classes.restaurantPicker}>
              <RestaurantsContainer targetRestaurant={targetRestaurant} />
            </div>

            {selectedRestaurant?.restaurantCode && (
              <div className={classes.restaurantPicker}>{selectedStage === MenuStages.PLAYGROUND ? <MenuVersionPicker /> : <span>{`Version ${commitId}`}</span>}</div>
            )}
          </>
        )}
        <div className={classes.logout}>
          <Logout />
        </div>
        {isTaskRouterPage && <TaskRouterHeaderInfo />}
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Header from '../components/Layout/Header';
import TaskRouterBody from '../components/TaskRouterBody';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  taskRouterPageClass: {},
}));

const TaskRouterPage = () => {
  const { taskRouterPageClass } = useStyles();

  return (
    <div data-testid="task-router-page" className={taskRouterPageClass}>
      <Header isTaskRouterPage />
      <TaskRouterBody />
    </div>
  );
};

export default TaskRouterPage;

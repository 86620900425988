import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export function selectHypothesisFrame(state: RootState) {
  return state.messages.hypothesisFrame;
}

const selectMessages = (state: RootState) => state.messages;
const selectIsAIActive = createSelector(selectMessages, (messages) => messages.isAIActive);
const selectIsWebSocketConnected = createSelector(selectMessages, (messages) => messages.isConnected);
const selectIsNewSession = createSelector(selectMessages, (messages) => messages.isNewSession);
const selectRestaurantStaffIntervention = createSelector(selectMessages, (messages) => messages.restaurantStaffIntervention);

export { selectIsAIActive, selectIsNewSession, selectIsWebSocketConnected, selectRestaurantStaffIntervention };

import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Card, CardContent, LinearProgress, Typography } from '@mui/material';
import { cartActions, COUPON_APPLY_STATUS } from '../../reducers/cartSlice';
import Colors from '../../utils/color';

interface Props {
  couponCode: string;
  status: COUPON_APPLY_STATUS;
}

export default function CouponApplyStatus({ status, couponCode }: Props) {
  const dispatch = useDispatch();

  const handleRemove = () => {
    dispatch(cartActions.clearCouponApplyStatus());
  };

  const renderBottom = () => {
    if (status === COUPON_APPLY_STATUS.INVALID) {
      return (
        <Box
          data-testid="invalid-bottom"
          sx={{ backgroundColor: Colors.torchRed, color: 'white', fontSize: '10px', letterSpacing: '1px', fontWeight: 500, p: '3px 10px', textTransform: 'uppercase' }}
        >
          Invalid Coupon Number
        </Box>
      );
    }

    if (status === COUPON_APPLY_STATUS.PROCESSING) {
      return (
        <LinearProgress
          data-testid="processing-bottom"
          variant="determinate"
          value={66}
          sx={{ backgroundColor: Colors.alto, '& .MuiLinearProgress-bar': { backgroundColor: Colors.silverChalice } }}
        />
      );
    }

    return <></>;
  };

  if (status === COUPON_APPLY_STATUS.SUCCESS) return <></>;

  return (
    <Card elevation={3} sx={{ mt: 2, cursor: 'pointer', borderRadius: 1.5 }}>
      <CardContent sx={{ pb: '4px !important' }}>
        <Typography variant="subtitle2" sx={{ mb: '5px', fontWeight: 'bold' }}>
          {`Coupon #${couponCode}`}
        </Typography>
        <Typography variant="subtitle2" sx={{ fontSize: '12px' }}>
          {status === COUPON_APPLY_STATUS.PROCESSING ? 'Processing...' : 'Invalid coupon'}
        </Typography>
        <Box sx={{ textAlign: 'right' }}>
          <Button variant="text" color="error" size="small" sx={{ textTransform: 'initial', fontWeight: 'bold' }} onClick={handleRemove}>
            Remove
          </Button>
        </Box>
      </CardContent>
      {renderBottom()}
    </Card>
  );
}

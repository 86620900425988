import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../app/store';
import { DUMMY_ITEM, KEY, POS_ID } from '../constants';
import { CartItemAdditionTypes } from '../constants/event';
import { GenericObjectType } from '../types';
import { findObjectInObjectByKeyValue, readEnvVariable } from '../utils';
import { post } from '../utils/api';
import { CartItem } from '../utils/cart';
import { POS_BOOLEAN_PROPERTIES_LIST, PROD_ACCESS_DETAILS } from '../utils/constants';
import inMemoryGroupIdMapping from '../utils/inMemoryGroupIdMapping';
import logger from '../utils/logger';
import { getMenuItemPrice, sortChildrenModGroup } from '../utils/menu';
import { getOrderApiToken, getOrderApiUrl } from '../utils/network';
import { GenericMap } from '../utils/types';
import { sendOrderMetrics } from './cartSlice';
import { selectMenuVersion } from './menuSlice';
import { CheckTransmissionMessage, messagingActions, TransmissionMessage } from './messagingSlice';
import { selectRestaurant, selectStage } from './restaurantSlice';

interface SendItemProperties {
  id: number;
  name: string;
  memo?: string;
  quantity: number;
  price: string;
  options?: SendItemProperties[];
  children: SendItemProperties[];
  pos_specific_properties: any;
  addedBy?: CartItemAdditionTypes;
}

interface SendOrderData {
  check_id: string;
  store_id: string;
  final?: boolean;
  items: SendItemProperties[];
  source: 'PRESTOVOICE';
  request_id: string;
  session_id: string;
  seq_id: number;
  couponno?: string;
  environment?: string;
  menu_version: string;
}

export interface OrderTransmissionMessage extends TransmissionMessage {
  data: SendOrderData;
}

interface SendOrderResponse {
  status: {
    code: number;
    type: string;
    message: string;
  };
  pos_partner: string;
  datetime: string;
  result: {
    transaction_id: string;
  };
  cartItemIds: number[];
}

interface CheckTransactionStatusData {
  transaction_id: string;
  restaurant_code: string;
  cartItemIds: number[];
  isFinal?: boolean;
}

interface CheckTransactionStatusResponse {
  status: {
    code: number;
    type: string;
    message: string;
  };
  pos_partner: string;
  datetime: string;
  isFinal?: boolean;
  result: {
    transaction: {
      status: string;
      created_at: string;
      updated_at: string;
    };
    data: {
      check: any; // Leave as is for now as we don't use it at this moment
    };
  };
}

export interface Transactions {
  [transaction_id: string]: {
    timer: number | undefined;
    status: string | null;
    data: CheckTransactionStatusResponse | null;
  };
}

export interface OrderState {
  currentSessionId: string | null;
  currentTransactionId: string | null;
  currentTransactionItems: {
    [cartItemId: number]: string;
  };
  transactions: Transactions;
  seqId: number;
  total: string;
  subtotal: string;
  tax: string;
  orderError: string;
  sendOrderFailed?: boolean;
  completeClickCount: {
    [transaction_id: string]: number;
  };
}

export enum TransactionStatus {
  complete = 'COMPLETED',
  pending = 'PENDING',
  failed = 'FAILED',
}

export const initialState: OrderState = {
  currentSessionId: null,
  currentTransactionId: null,
  transactions: {},
  currentTransactionItems: {},
  seqId: 0,
  total: '',
  subtotal: '',
  tax: '',
  orderError: '',
  completeClickCount: {},
};

export const checkTransactionStatus = createAsyncThunk('order/checkTransactionStatus', async (checkTransactionStatus: CheckTransactionStatusData, thunkAPI) => {
  const apiToken = await getOrderApiToken((thunkAPI.getState() as RootState).config.NODE_ENV);
  if (!apiToken) {
    throw thunkAPI.rejectWithValue('No API Token Found');
  }
  const result = await (
    await fetch(
      `${getOrderApiUrl((thunkAPI.getState() as RootState).config.NODE_ENV)}/${checkTransactionStatus.restaurant_code}/transaction/${checkTransactionStatus.transaction_id}/status`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'api-key': apiToken,
          ...PROD_ACCESS_DETAILS,
        },
      }
    )
  ).json();
  return {
    ...result,
    transaction_id: checkTransactionStatus.transaction_id,
    cartItemIds: checkTransactionStatus.cartItemIds,
    isFinal: checkTransactionStatus.isFinal,
  };
});

export const sendOrder = createAsyncThunk(
  'order/sendOrder',
  async ({ cartItems, cartValid, isFinal }: { cartItems: GenericMap<CartItem>; cartValid: boolean; isFinal?: boolean }, thunkAPI) => {
    const groupIdMappings = inMemoryGroupIdMapping.getGroupIdMapping();
    const { ITEM_BY_ITEM: isItemByItemEnabled, NODE_ENV } = (thunkAPI.getState() as RootState).config;
    const cartItemIds = Object.values(cartItems).reduce((acc: number[], { cartItemId }) => {
      if (cartItemId) acc.push(cartItemId);
      return acc;
    }, []);
    thunkAPI.dispatch(orderActions.startSendOrder(cartItemIds));
    const rootState = thunkAPI.getState() as RootState;
    const sessionId = rootState.order.currentSessionId ? rootState.order.currentSessionId : `${uuidv4()}`; // create a session only when there is no active session
    thunkAPI.dispatch(orderActions.setCurrentSession(sessionId));
    const { cartItemsQuantity: itemsQuantity, couponItem } = rootState.cart;

    let restaurantCode = rootState.restaurant.selectedRestaurantCode || undefined;
    if (!restaurantCode) {
      restaurantCode = rootState.messages.startFrame?.data.restaurant_code;
      if (!restaurantCode) {
        throw thunkAPI.rejectWithValue('No Current restarauntCode selected!');
      }
    }
    let pathToGroupId: string = '';
    const boolList = ['true', 'false', ''];

    const getCartItemProperties = (cartItem: CartItem, groupId: string[], isModifier = false) => {
      const posProperties = Object.values(cartItem.posProperties).reduce((acc, { key, value }) => {
        if (key === POS_ID && value !== DUMMY_ITEM) {
          groupId.push(value);
        }
        acc[key] = POS_BOOLEAN_PROPERTIES_LIST.includes(key) && boolList.includes(value.toLowerCase()) ? value.toLowerCase() === boolList[0] : value;
        return acc;
      }, {} as { [key: string]: any });

      if (cartItem.modcode) {
        posProperties.modcode = cartItem.modcode;
      }
      console.log(cartItem);

      pathToGroupId = groupId.slice(0, -1).join('__');
      console.log('Finding component id for:', pathToGroupId);
      if (groupIdMappings[pathToGroupId]) {
        posProperties['group_id'] = groupIdMappings[pathToGroupId];
        posProperties['component_id'] = groupIdMappings[pathToGroupId];
        pathToGroupId = '';
      }
      const posIDObject: GenericObjectType = findObjectInObjectByKeyValue(cartItem.posProperties, KEY, POS_ID);
      const childItems = sortChildrenModGroup(cartItem).reduce((acc, modGroup) => {
        if (posIDObject?.value !== DUMMY_ITEM) groupId.push(modGroup.prpName);
        const selectedItems = Object.values(modGroup.selectedItems).map((item) => {
          const cartItem = getCartItemProperties(item, [...groupId], true);
          return cartItem;
        });
        groupId.pop();
        acc.push(...selectedItems);
        return acc;
      }, [] as SendItemProperties[]);

      const itemData: SendItemProperties = {
        id: parseInt(cartItem.id),
        name: cartItem.name,
        memo: cartItem.itemLevelMemo || undefined,
        quantity: isModifier ? 1 : itemsQuantity[cartItem.cartItemId] || 1,
        price: (getMenuItemPrice(cartItem, cartItem.modality) / 100).toFixed(2), // Apply price override
        options: childItems,
        children: childItems,
        pos_specific_properties: posProperties,
        addedBy: cartItem.addedBy || CartItemAdditionTypes.human,
      };
      return itemData;
    };

    const items = Object.values(cartItems).map((item) => {
      let groupId: string[] = [];

      const cartItem = getCartItemProperties(item, groupId);
      groupId = [];
      return cartItem;
    });

    // This is tighter than it was, but this really should be atomic...
    thunkAPI.dispatch(orderSlice.actions.incSeqId({}));
    const seqId = (thunkAPI.getState() as RootState).order.seqId;

    const orderData: SendOrderData = {
      check_id: '-1', // TODO Do we send the same transaction_id here???
      store_id: restaurantCode,
      final: isFinal ? true : false,
      items,
      source: 'PRESTOVOICE',
      request_id: uuidv4(),
      session_id: sessionId,
      seq_id: seqId,
      menu_version: rootState.menu?.selectedMenuVersion?.commitId || '',
    };

    // Apply Coupon Item
    if (couponItem && !couponItem.isApplied) {
      orderData.couponno = couponItem.couponno;
    }

    const payload: Partial<OrderTransmissionMessage> = {
      data: {
        ...orderData,
        environment: readEnvVariable('DEPLOY_ENV'),
      },
    };

    console.log('HTTP Request | Endpoint: ', `${getOrderApiUrl(NODE_ENV)}/${restaurantCode}/qs/order`, ' | data: ', JSON.stringify(payload));
    thunkAPI.dispatch(messagingActions.sendOrder(payload as any));

    try {
      if (cartValid && (isFinal || isItemByItemEnabled)) {
        const apiToken = await getOrderApiToken(NODE_ENV);
        if (!apiToken) {
          throw thunkAPI.rejectWithValue('No API Token Found');
        }

        const data = await post({
          url: `${getOrderApiUrl(NODE_ENV)}/${restaurantCode}/qs/order`,
          headers: {
            'api-key': apiToken,
            ...PROD_ACCESS_DETAILS,
          },
          data: orderData,
          successCallback: (_data: any) => {
            // Send order metric
            thunkAPI.dispatch(sendOrderMetrics());
          },
          errorCallback: () => {
            throw thunkAPI.rejectWithValue('Send Order Failed');
          },
        });

        if (data) {
          const checkTransactionStatusData: CheckTransactionStatusData = {
            transaction_id: data.result.transaction_id,
            restaurant_code: restaurantCode,
            cartItemIds,
            isFinal,
          };
          const timer = setInterval(() => {
            try {
              thunkAPI.dispatch(checkTransactionStatus(checkTransactionStatusData));
            } catch (error) {
              logger.error('Failed checking transaction status', error);
            }
          }, 1000);
          return { ...data, timer, cartItemIds, seqId, isFinal };
        }
      }
    } catch (err) {
      throw err;
    }
  }
);

const resetSessionVariables = (state: OrderState) => {
  state.currentSessionId = null;
  state.currentTransactionId = null;
  state.currentTransactionItems = {};
  state.seqId = 0;
  state.total = '';
  state.subtotal = '';
  state.tax = '';
  state.completeClickCount = {};
  state.sendOrderFailed = false;
  console.log('-currentSessionId in order state-', state.currentSessionId);
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    startSendOrder: (state, action: PayloadAction<number[]>) => {
      for (let cartItemId of action.payload) {
        if (!state.currentTransactionItems[cartItemId]) {
          state.currentTransactionItems[cartItemId] = 'SENDING';
        }
      }
    },
    resetSession: (state) => {
      resetSessionVariables(state);
    },
    setCurrentSession: (state, action: PayloadAction<string>) => {
      state.currentSessionId = action.payload;
      console.log('-currentSessionId in order state-', state.currentSessionId);
    },
    incSeqId: (state, action: any) => {
      state.seqId += 1;
    },
    setOrderError: (state, action: PayloadAction<string>) => {
      state.orderError = action.payload;
    },
    increaseCompleteClickCount: (state) => {
      if (state.currentTransactionId && state.currentTransactionId in state.completeClickCount) {
        state.completeClickCount[state.currentTransactionId] += 1;
      } else if (state.currentTransactionId) {
        // update count whever transaction id updates
        state.completeClickCount = {};
        state.completeClickCount[state.currentTransactionId] = 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendOrder.rejected, (state, action) => {
      console.error('Failed To Send Order', action.payload);
      state.sendOrderFailed = true;
      Object.keys(state.currentTransactionItems).forEach((cartItemId) => {
        if (cartItemId) {
          state.currentTransactionItems[parseInt(cartItemId)] = 'FAILED';
        }
      });
    });
    builder.addCase(sendOrder.fulfilled, (state, action) => {
      if (!action.payload) return;

      const currentTransactionId = action.payload.result.transaction_id;
      state.sendOrderFailed = false;
      state.orderError = '';
      state.currentTransactionId = currentTransactionId;
      state.transactions[currentTransactionId] = {
        data: null,
        timer: action.payload.timer,
        status: 'PENDING',
      };

      for (let cartItemId of action.payload.cartItemIds) {
        if (state.currentTransactionItems[cartItemId] === 'SENDING') {
          state.currentTransactionItems[cartItemId] = 'PENDING';
        }
      }
      // Stop interval timer for all but the newest transaction_id, but keep the status
      Object.keys(state.transactions)
        .filter((transaction_id) => transaction_id !== currentTransactionId)
        .forEach((transaction_id) => {
          clearInterval(state.transactions[transaction_id].timer);
        });
    });
    builder.addCase(checkTransactionStatus.fulfilled, (state, action: PayloadAction<CheckTransactionStatusResponse & CheckTransactionStatusData>) => {
      const status = action.payload.result?.transaction?.status || 'UNKNOWN';
      state.orderError = '';
      state.transactions[action.payload.transaction_id].data = action.payload;
      state.transactions[action.payload.transaction_id].status = status;

      for (let cartItemId of action.payload.cartItemIds) {
        if (!state.currentTransactionItems[cartItemId]) {
          state.currentTransactionItems[cartItemId] = status;
        } else if (state.currentTransactionItems[cartItemId] !== 'COMPLETED') {
          state.currentTransactionItems[cartItemId] = status;
        }
      }
      if (['COMPLETED', 'FAILED'].includes(status)) {
        clearInterval(state.transactions[action.payload.transaction_id].timer);
      }
    });
    builder.addCase(messagingActions.messageReceived, (state, action: PayloadAction<TransmissionMessage[]>) => {
      const messages = action.payload;
      messages.forEach((message) => {
        if (message.event === 'check') {
          const checkMessage = message as CheckTransmissionMessage;
          if (checkMessage.session_id === state.currentSessionId && checkMessage.data.status === 'ok' && checkMessage.data.transaction_id === state.currentTransactionId) {
            state.total = checkMessage.data.check?.total ? checkMessage.data.check?.total : state.total;
            state.subtotal = checkMessage.data.check?.total ? checkMessage.data.check?.subtotal : state.subtotal;
            state.tax = checkMessage.data.check?.total ? checkMessage.data.check?.tax : state.tax;
            if (process.env.NODE_ENV !== 'production') {
              console.log('Websocket Communication | Check Event | data: ', JSON.stringify(messages));
              console.log('Subtotal: ', state.subtotal, 'Tax: ', state.tax, 'Total: ', state.total);
            }
          }
        }
      });
    });
    builder.addMatcher(isAnyOf(selectRestaurant.fulfilled, selectStage.fulfilled, selectMenuVersion.fulfilled), (state) => {
      resetSessionVariables(state);
    });
  },
});

export const orderActions = orderSlice.actions;

export default orderSlice.reducer;

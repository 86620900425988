import { FormControlLabel, Switch, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectIsAgentAvailable } from '../../redux/features/taskRouter/taskRouter.selector';
import { toggleAgentAvailability } from '../../redux/features/taskRouter/taskRouter.slice';

const useStyles = makeStyles((theme: Theme) => ({
  switchClass: {
    paddingLeft: theme.spacing(2),
    '& .Mui-checked': {
      color: '#ffc107 !important',
      '& +.MuiSwitch-track': {
        backgroundColor: '#ffc107 !important',
      },
    },
  },
}));

const TaskRouterHeaderInfo = () => {
  const { switchClass } = useStyles();
  const dispatch = useAppDispatch();
  const isAgentAvailable = useAppSelector(selectIsAgentAvailable);
  const handleAvailability = () => dispatch(toggleAgentAvailability());

  return (
    <>
      <FormControlLabel
        className={switchClass}
        control={<Switch checked={isAgentAvailable} onChange={handleAvailability} color="warning" />}
        label={isAgentAvailable ? 'Available' : 'Unavailable'}
      />
    </>
  );
};

export default TaskRouterHeaderInfo;

import { useSelector } from 'react-redux';
import { useShallowSelector } from '../app/hooks';
import { RootState } from '../app/store';
import { Transactions, TransactionStatus } from '../reducers/orderSlice';
import { getCodeNameMapping } from '../selectors/menu';
import { CartItem, CartModifierGroup } from './cart';
import { ModSymbolCodeNameMappingType, READ_REP_MAP } from './mappings';
import { sortChildrenModGroup } from './menu';
import { printCurrency } from './money';
import { OH } from './constants';

const SIZE_MODIFIERS = ['Small', 'Medium', 'Large', 'Regular', 'sml', 'med', 'lge'];

export function getMessageWithTotal({
  message,
  ITEM_BY_ITEM,
  currentTransactionId,
  transactions,
  completeClickCount,
  total,
  title,
}: {
  message: string;
  ITEM_BY_ITEM: boolean;
  currentTransactionId: string | null;
  transactions: Transactions;
  completeClickCount: {
    [transaction_id: string]: number;
  };
  total: number | null;
  title?: string;
}) {
  if (!ITEM_BY_ITEM || (currentTransactionId && transactions[currentTransactionId]?.status !== TransactionStatus.complete && completeClickCount[currentTransactionId] >= 2)) {
    return 'Please pull forward to the window. Your order total will be available there.';
  }

  // Completed
  if (currentTransactionId) {
    if (transactions[currentTransactionId]?.status === TransactionStatus.complete && total) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Total in getMessageWithTotal: ', (total / 100).toFixed(2));
      }
      return message.replaceAll(
        '{TOTAL}',
        printCurrency(total, 'USD')
          .replace('$', '')
          .replace('.', ' ')
          .replace(' 00', total === 100 ? ' dollar' : ' dollars') // if total is trailing 00, we suffix “dollars” at the end.
          .replace(' 0', ' ' + OH + ' ') // Read back with an “Oh” if there is a zero directly after the currency decimal mark.
      );
    }

    return 'Please proceed to the window for your total';
  }

  return 'Please send order request first.';
}

function isSizeModifier(modifierName: string) {
  return SIZE_MODIFIERS.find((s) => s.toLowerCase() === modifierName.toLowerCase());
}

function getCartItemNameWithModifiers(cartItem: CartItem, codeNameMapping: ModSymbolCodeNameMappingType) {
  let name = cartItem.name.includes('/upsell') ? '' : cartItem.name;

  let originSortedChildModifierGroups = sortChildrenModGroup(cartItem).filter((modGroup) => Object.keys(modGroup.selectedItems).length > 0);

  const childModifierGroups: CartModifierGroup[] = [];
  let sizeModifierGroup: CartModifierGroup | null = null;

  originSortedChildModifierGroups.forEach((modifierGroup) => {
    let isSizeGroup = modifierGroup.name?.toLowerCase().includes('size');
    if (!isSizeGroup) {
      const selectedItems = Object.values(modifierGroup.selectedItems || {});
      if (selectedItems.length > 0) {
        isSizeGroup = selectedItems.every((selectedItem) => {
          return isSizeModifier(selectedItem.name || '');
        });
      }
    }

    if (isSizeGroup) sizeModifierGroup = modifierGroup;
    else childModifierGroups.push(modifierGroup);
  });

  if (childModifierGroups.length > 0) {
    cartItem.name.includes('/upsell') ? (name += '') : (name += ' with');
    childModifierGroups.forEach((modGroup, modIndex) => {
      const selectedItems = Object.values(modGroup.selectedItems || {});
      if (selectedItems.length === 1 && selectedItems[0].name === 'DECLINED') return '';

      selectedItems.map((selectedItem, index) => {
        let toAdd = !selectedItem.name.includes('!!') ? ` ${selectedItem.name}` : '';
        let rep = Object.keys(READ_REP_MAP).filter((k) => k.toLowerCase() == selectedItem.name.toLowerCase());
        if (rep[0]) {
          toAdd = toAdd.replace(rep[0].toLowerCase(), READ_REP_MAP[rep[0]]);
        }
        let getModName = (mcName: string, modName: string) => (codeNameMapping[mcName]?.speak?.(modName) || codeNameMapping[mcName]?.name) + ` ${toAdd}`;
        toAdd = selectedItem.modcode ? getModName(selectedItem.modcode, toAdd) : '' + toAdd;
        if (toAdd) {
          name += toAdd;
          if (modIndex < childModifierGroups.length - 1 || index < selectedItems.length - 1) {
            name += ',';
          }
        }
      });
    });
  }

  if (sizeModifierGroup) {
    const selectedItems = Object.values((sizeModifierGroup as CartModifierGroup).selectedItems || {});
    if (selectedItems.length > 0) {
      const sizeModifier = SIZE_MODIFIERS.find((sizeOption) => selectedItems[0].name.toLowerCase().includes(sizeOption.toLowerCase()));
      name = sizeModifier + ' ' + name;
    }
  }
  return name;
}

export default function useGetRepeatMessage() {
  const { cartItems, cartItemsQuantity } = useSelector((state: RootState) => state.cart);
  const codeNameMapping = useShallowSelector(getCodeNameMapping);

  if (Object.values(cartItems).length === 0) {
    return 'There’s no items in your order.';
  }

  const cartNameToCnt: Record<string, number> = {};
  Object.values(cartItems).forEach((item) => {
    const name = getCartItemNameWithModifiers(item, codeNameMapping);
    cartNameToCnt[name] = (cartNameToCnt[name] || 0) + (cartItemsQuantity[item.cartItemId] || 1);
  });

  let message = '';
  const totalCartLength = Object.keys(cartNameToCnt).filter((k) => k).length;
  Object.keys(cartNameToCnt)
    .filter((k) => k)
    .forEach((name, index) => {
      const cnt = cartNameToCnt[name];
      if (cnt === 1) {
        message += `1 ${name}`;
      } else {
        message += `${cnt} ${name}`;
      }

      if (index === totalCartLength - 2) {
        message += ', and ';
      }
      if (index < totalCartLength - 2) {
        message += ', ';
      }
    });

  return message.trim() ? `I have ${message}, is that correct?` : '';
}

export const getItemUnavailableMessage = (itemName: string) => `I'm sorry, but ${itemName} is unavailable`;

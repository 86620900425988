import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../app/store';
import { EventTypes } from '../constants/event';
import { ModalityType } from '../generated-interfaces/graphql';
import { get } from '../utils/api';
import { CartItem, recursivelyFindSelectedItemNode } from '../utils/cart';
import { PROD_ACCESS_DETAILS } from '../utils/constants';
import logger from '../utils/logger';
import { ModSymbolCodeNameMappingType } from '../utils/mappings';
import { ParsedMenuItem, ParsedModifierGroup } from '../utils/menu';
import { getOrderApiToken, getOrderApiUrl } from '../utils/network';
import { GenericMap } from '../utils/types';
import { selectMenuVersion } from './menuSlice';
import { messagingActions, TransmissionMessage } from './messagingSlice';
import { orderActions, TransactionStatus } from './orderSlice';
import { selectRestaurant, selectStage } from './restaurantSlice';

export enum COUPON_APPLY_STATUS {
  PROCESSING = 'PROCESSING',
  INVALID = 'INVALID',
  SUCCESS = 'SUCCESS',
}

const APPLY_LOYALTY_CODE = 'apply_loyalty_code';

interface CheckTransactionStatusData {
  transaction_id: string;
  restaurant_code: string;
}

export const sendOrderMetrics = createAsyncThunk('cart/sendOrderMetrics', async (undefined, thunkAPI) => {
  console.log('Sending order metrics');
  let restaurantCode = (thunkAPI.getState() as RootState).restaurant.selectedRestaurantCode || undefined;
  if (!restaurantCode) {
    restaurantCode = (thunkAPI.getState() as RootState).messages.startFrame?.data.restaurant_code;
    if (!restaurantCode) {
      throw thunkAPI.rejectWithValue('No Current restarauntCode selected!');
    }
  }

  const cartItems = Object.values((thunkAPI.getState() as RootState).cart.cartItems);
  console.log('ARJ CART ITEMS', cartItems);
  let smallCart: { name: string; id: string; children: { name: string; id: string }[] }[] = [];
  cartItems.forEach((item) => {
    let { name, id, childModifierGroups } = item;
    let groups = Object.values(childModifierGroups);
    let children: { name: string; id: string }[] = [];
    groups.forEach((g) => {
      Object.values(g.selectedItems).map((child) => {
        let { name, id } = child;
        children.push({ name, id });
      });
    });
    smallCart.push({
      name,
      id,
      children,
    });
  });

  console.log(smallCart);
  const orderMetrics = {
    store_id: restaurantCode,
    items: smallCart,
    source: 'PRESTOVOICE',
    request_id: uuidv4(),
    // session_id: sessionId,
  };

  const payload: Partial<TransmissionMessage & { data: { payload: any } }> = {
    data: { payload: orderMetrics },
    event: EventTypes.info,
  };
  thunkAPI.dispatch(messagingActions.sendInfo(payload as any));
  return;
});

const checkTransactionStatus = createAsyncThunk('cart/checkCouponTransactionStatus', async (payload: CheckTransactionStatusData, thunkAPI) => {
  const apiToken = await getOrderApiToken((thunkAPI.getState() as RootState).config.NODE_ENV);
  if (!apiToken) {
    throw thunkAPI.rejectWithValue('No API Token Found');
  }

  const result = await get({
    url: `${getOrderApiUrl((thunkAPI.getState() as RootState).config.NODE_ENV)}/${payload.restaurant_code}/transaction/${payload.transaction_id}/status`,
    headers: {
      'api-key': apiToken,
      ...PROD_ACCESS_DETAILS,
    },
  });

  return { ...result, transaction_id: payload.transaction_id };
});

// Apply Loyalty Code
export const applyLoyaltyCode = createAsyncThunk('cart/applyLoyaltyCode', async ({ couponCode }: { couponCode: number }, thunkAPI) => {
  const rootState = thunkAPI.getState() as RootState;
  const {
    config: { NODE_ENV },
  } = rootState;

  let restaurantCode = rootState.restaurant.selectedRestaurantCode || undefined;
  let currentSessionId = rootState.order.currentSessionId;

  if (!currentSessionId) {
    currentSessionId = `${uuidv4()}`;
    thunkAPI.dispatch(orderActions.setCurrentSession(currentSessionId));
  }

  if (!restaurantCode) {
    restaurantCode = rootState.messages.startFrame?.data.restaurant_code;
    if (!restaurantCode) {
      throw thunkAPI.rejectWithValue('No Current restarauntCode selected!');
    }
  }

  const apiToken = await getOrderApiToken(NODE_ENV);
  if (!apiToken) {
    throw thunkAPI.rejectWithValue('No API Token Found');
  }

  try {
    const requestPayload = {
      check_id: '-1', // check ID of target check
      loyalty_code: couponCode.toString(), // loyalty offer to apply
      session_id: currentSessionId,
      source: 'PRESTOVOICE',
      // request_id: 'qs_apply_loyalty_code',
      request_id: uuidv4(),
      store_id: restaurantCode,
    };

    const response = await fetch(`${getOrderApiUrl(NODE_ENV)}/${restaurantCode}/apply/loyalty`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api-key': apiToken,
        ...PROD_ACCESS_DETAILS,
      },
      body: JSON.stringify(requestPayload),
    });

    if (response?.status !== 200) return;
    const data = await response.json();
    if (!data?.result?.transaction_id) return;

    const checkTransactionStatusData: CheckTransactionStatusData = {
      transaction_id: data.result?.transaction_id,
      restaurant_code: restaurantCode,
    };

    const timer = setInterval(() => {
      try {
        thunkAPI.dispatch(checkTransactionStatus(checkTransactionStatusData));
      } catch (error) {
        logger.error('Failed checking transaction status', error);
      }
    }, 1000);

    return { ...data, timer, couponCode: requestPayload.loyalty_code };
  } catch (err) {
    console.log('Apply Loyalty Error', err);
    throw err;
  }
});
export interface CouponItem {
  name: string;
  couponno: string;
  modifiers: string[];
  isApplied?: boolean;
}
export interface CartState {
  cartItems: GenericMap<CartItem>;
  sequenceId: number;
  modality: ModalityType;
  cartItemsQuantity: Record<string, number>;
  couponItem?: CouponItem;
  loyaltyStatusTimer?: number;
  couponApplyStatus?: COUPON_APPLY_STATUS;
  couponCode?: string;
}

export const initialState: CartState = {
  cartItems: {},
  sequenceId: 1,
  modality: ModalityType.Dinein,
  cartItemsQuantity: {},
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart: (state, action: PayloadAction<CartItem>) => {
      state.cartItems[action.payload.cartItemId] = action.payload;
      state.cartItemsQuantity[action.payload.cartItemId] = 1;
      state.sequenceId++;
    },
    updateCartItem: (state, { payload: { cartItem, ignoreHypothesis } }: PayloadAction<{ cartItem: CartItem; ignoreHypothesis: boolean }>) => {
      state.cartItems[cartItem.cartItemId] = cartItem;
    },
    selectModifier: (
      state,
      {
        payload: { cartItemId, menuItem, modGroup, selected, modCode, modSymbolMapping },
      }: PayloadAction<{
        cartItemId: number;
        menuItem: ParsedMenuItem;
        modGroup: ParsedModifierGroup;
        selected: boolean;
        modCode: string;
        modSymbolMapping: ModSymbolCodeNameMappingType;
      }>
    ) => {
      let node: CartItem = state.cartItems[cartItemId];

      const selectedItemNodes: CartItem[] = [];
      recursivelyFindSelectedItemNode(state.cartItems[cartItemId], modGroup.id, selectedItemNodes);
      if (selectedItemNodes.length) {
        node = selectedItemNodes[0];
      }

      if (!node.childModifierGroups[modGroup.id]) {
        node.childModifierGroups[modGroup.id] = {
          ...modGroup,
          cartModifierGroupId: String(state.sequenceId++),
          menuModifierGroupId: modGroup.id,
          name: modGroup.name,
          selectedItems: {},
        };
      }

      const existing = node.childModifierGroups[modGroup.id].selectedItems;
      const menuItemIsAlreadySelected = existing && existing[menuItem.itemId];

      if (modGroup.maximumSelections === 1) {
        // Remove other choices automatically
        // Need to remove other choices when select different choice
        if (!menuItemIsAlreadySelected) {
          node.childModifierGroups[modGroup.id].selectedItems = {};
        }
      } else if (selected && existing && modGroup.maximumSelections === Object.keys(existing).length) {
        // Do not allow selection of more then maximumSelections
        return;
      }

      if (!selected) {
        delete node.childModifierGroups[modGroup.id].selectedItems[menuItem.itemId];
      } else {
        // Select a new modifier or update the modcode
        const mappedModCode = modSymbolMapping[modCode]?.code || '';
        node.childModifierGroups[modGroup.id].selectedItems[menuItem.itemId] = {
          ...menuItem,
          modality: state.modality,
          cartItemId: state.sequenceId++,
          childModifierGroups: {},
          modcode: mappedModCode,
        };
      }

      // state.cartItems[cartItemId] = node
    },
    cloneCartItem: (state, { payload }: PayloadAction<string>) => {
      state.sequenceId += 1;
      const cartItems = {
        ...state.cartItems,
        [state.sequenceId]: {
          ...state.cartItems[payload],
          cartItemId: state.sequenceId,
        },
      };
      state.cartItems = { ...cartItems };
      state.cartItemsQuantity[state.sequenceId] = state.cartItemsQuantity[payload];
      state.sequenceId += 1;
    },
    deleteCartItem: (state, { payload }: PayloadAction<string>) => {
      delete state.cartItems[payload];
      delete state.cartItemsQuantity[payload];
    },
    clearCart: (state) => {
      state.cartItems = {};
      state.cartItemsQuantity = {};
      state.couponItem = undefined;
      state.couponApplyStatus = undefined;
      state.couponCode = undefined;
    },
    clearCouponApplyStatus: (state) => {
      state.couponApplyStatus = undefined;
    },
    updateQuantity: (state, { payload }: PayloadAction<{ cartItemId: string; quantity: number }>) => {
      state.cartItemsQuantity[payload.cartItemId] = payload.quantity;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(applyLoyaltyCode.fulfilled, (state, action) => {
      if (!action.payload) return;
      if (state.loyaltyStatusTimer) {
        clearInterval(state.loyaltyStatusTimer);
      }
      state.couponCode = action.payload.couponCode;
      state.couponApplyStatus = COUPON_APPLY_STATUS.PROCESSING;
      state.loyaltyStatusTimer = action.payload.timer;
    });
    builder.addCase(checkTransactionStatus.fulfilled, (state, action) => {
      const status = action.payload.result?.transaction?.status || 'UNKNOWN';

      if (status === 'COMPLETED') {
        console.log('Loyalty Transaction Payload:', action.payload.result);
        const { couponno, coupontext } = action.payload.result.data;

        if (!couponno || !coupontext) {
          clearInterval(state.loyaltyStatusTimer);
          state.loyaltyStatusTimer = undefined;
          state.couponApplyStatus = COUPON_APPLY_STATUS.INVALID;
          return;
        }

        if (coupontext.includes('_')) {
          /**
           * Normal case: <desc>_<comma separated offer item list>
           */
          const [name, modifiersSubstring, ...rest] = coupontext.split('_');

          if (name) {
            state.couponItem = {
              couponno,
              name,
              modifiers: modifiersSubstring.split(', '),
            };
          }
        } else {
          /**
           * Special case: <comma separated offer item list>
           */
          state.couponItem = {
            couponno,
            name: '',
            modifiers: coupontext.split(', '),
          };
        }
        state.couponApplyStatus = COUPON_APPLY_STATUS.SUCCESS;
      }

      if (status === TransactionStatus.failed && action.payload.result?.data?.request === APPLY_LOYALTY_CODE) {
        state.couponApplyStatus = COUPON_APPLY_STATUS.INVALID;
      }

      if (['COMPLETED', 'FAILED'].includes(status) && state.loyaltyStatusTimer) {
        clearInterval(state.loyaltyStatusTimer);
        state.loyaltyStatusTimer = undefined;
      }
    });
    builder.addCase(checkTransactionStatus.rejected, (state) => {
      clearInterval(state.loyaltyStatusTimer);
      state.loyaltyStatusTimer = undefined;
      state.couponApplyStatus = COUPON_APPLY_STATUS.INVALID;
    });
    builder.addMatcher(isAnyOf(selectRestaurant.fulfilled, selectStage.fulfilled, selectMenuVersion.fulfilled), (state, action) => {
      state.cartItems = {};
      if (state.loyaltyStatusTimer) {
        clearInterval(state.loyaltyStatusTimer);
      }
    });
  },
});

export const cartActions = cartSlice.actions;
export const { addItemToCart, updateCartItem, selectModifier, cloneCartItem, deleteCartItem, clearCart, updateQuantity } = cartSlice.actions;

export default cartSlice.reducer;

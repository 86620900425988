import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NodeEnv } from '../../../constants/enums';

export interface ConfigState {
  NODE_ENV: NodeEnv;
  ITEM_BY_ITEM: boolean;
  DISABLE_INTENT_INTERVAL: number;
  FEATUREFLAG_TASKROUTER: boolean;
}

export const initialState: ConfigState = {
  NODE_ENV: process.env.REACT_APP_DEPLOY_ENV?.toUpperCase() || (process.env.NODE_ENV.toUpperCase() as any),
  ITEM_BY_ITEM: (process.env.REACT_APP_ITEM_BY_ITEM ? process.env.REACT_APP_ITEM_BY_ITEM : 'FALSE').toUpperCase() === 'TRUE',
  DISABLE_INTENT_INTERVAL: 3000, // in milisecond,
  FEATUREFLAG_TASKROUTER: (process.env.REACT_APP_FEATUREFLAG_TASKROUTER || 'FALSE').toUpperCase() === 'TRUE',
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<ConfigState>>) => {
      //This reducer is really not needed as environment variables which are passed are not changed during runtime
      Object.assign(state, action.payload);
    },
  },
});

export const { update } = configSlice.actions;
export default configSlice.reducer;

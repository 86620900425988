import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAvailableCategoryWithTimePeriod, selectTopLevelMenuItems } from '../../selectors/menu';
import { ParsedCategory } from '../../utils/menu';
import theme from '../../utils/theme';

const TimeBasedMenuNotification = () => {
  const availableCategoryWithTimePeriod = useSelector(selectAvailableCategoryWithTimePeriod);
  const topLevelMenuItem = useSelector(selectTopLevelMenuItems);

  if (!availableCategoryWithTimePeriod.length) return null;

  const getNotification = (category: ParsedCategory) => {
    const activeTimePeriod =
      category.activeTimePeriod && !category.activeTimePeriod.alwaysEnabled
        ? ` (${category.activeTimePeriod.start}-${category.activeTimePeriod.end} ${category.activeTimePeriod.day})`
        : '';
    const items = category.menuItems
      .map((menuItemId) => (topLevelMenuItem[String(menuItemId)] ? topLevelMenuItem[String(menuItemId)].name : ''))
      .filter((itemName) => itemName.length);
    const joinedItems = items.length > 1 ? items.join(', ') : items;
    return (
      <Typography key={category.id} variant="body2" sx={{ lineHeight: 1.8 }}>
        {category.name + activeTimePeriod + ': ' + joinedItems}
      </Typography>
    );
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main, padding: `${theme.spacing(1)} ${theme.spacing(2)}` }}>
      {availableCategoryWithTimePeriod.length && availableCategoryWithTimePeriod.map((category) => getNotification(category))}
    </Box>
  );
};

export default TimeBasedMenuNotification;
